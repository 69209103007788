import { carRental } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';
import { portalHost } from '../../const/host.const';

const { putActivateOemVehiclesDevices } = carRental.vehicle;

export const scopes = {
  activate: 'activate',
  deactivate: 'deactivate',
};

const devicesModule = createCustomStore(({ runAsyncFlow }) => withScopes(scopes,
  (
    {
      actions: {
        async putActivateOemDevices(
          { rootState, commit },
          { operatorUuid, data },
        ) {
          await runAsyncFlow(commit, {
            request: putActivateOemVehiclesDevices,
            params: [operatorUuid, data, portalHost(rootState)],
            scope: scopes.activate,
          });
        },
      },
    }
  )));

export default devicesModule;
